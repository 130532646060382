import Vue from 'vue'
import App from './App.vue'
// import axios from "axios";
import Vant from 'vant';
import { Dialog,ImagePreview } from 'vant';
import 'vant/lib/index.css';
// import Vconsole from 'vconsole'

Vue.config.productionTip = false
// Vue.prototype.$axios = axios
Vue.prototype.$dialog = Dialog
Vue.prototype.$imagePreview = ImagePreview

Vue.use(Vant)
// Vue.use(new Vconsole())
new Vue({
  render: h => h(App),
}).$mount('#app')
