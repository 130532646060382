<template>
  <div class="homePage">
    <div class="homePage_top" v-if="currentData">
      <van-swipe
        ref="homePage_swipe"
        class="homePage_swipe"
        @change="handleSwipeChange">
        <van-swipe-item v-for="(item, index) in currentData.swipe" :key="index">
          <template v-if="item.includes('.mp4')">
            <!-- <div
              v-if="!videoControls"
              class="overlay"
              @click="handleVideoClick">
              <van-icon class="overlay_icon" name="play-circle-o" />
            </div> -->
            <video
              ref="video"
              :src="item"
              :poster="currentData.poster"
              :controls="videoControls"
              autoplay
              preload="auto"
              playsinline=""
              webkit-playsinline=""
              x5-playsinline=""
              x-webkit-airplay="allow"></video>
          </template>
          <img v-else :src="item" alt="" @click="imagePreview(item)" />
        </van-swipe-item>
      </van-swipe>
      <h3 class="homePage_title">{{ currentData.title }}</h3>
    </div>
    <van-empty v-else description="暂无数据" />
  </div>
</template>

<script>
import JsonData from '@/config/data.json'
export default {
  name: 'HomePage',
  data() {
    return {
      videoControls: true,
      currentData: {},
    }
  },
  mounted() {
    if (this.getQueryString('id')) {
      this.currentData = JsonData.find(
        (item) => item.id === this.getQueryString('id')
      )
      if (!this.currentData) {
        return false
      }
      try {
        this.currentData.poster = require(`@/assets/poster/${this.currentData.poster}`)
      } catch (error) {
        console.log(error)
        this.currentData.poster = ''
      }
      let temp = []
      this.currentData.swipe.forEach((d) => {
        try {
          temp.push(require(`@/assets/swipe/${d}`))
        } catch (error) {
          console.log(error)
        }
      })
      this.currentData.swipe = temp
      this.$nextTick(() => {
        this.$refs.homePage_swipe.resize()
      })
    }
  },
  methods: {
    handleVideoClick() {
      this.$nextTick(() => {
        // this.videoControls = true
        const video = Array.isArray(this.$refs.video)
          ? this.$refs.video[0]
          : this.$refs.video
        this.$nextTick(() => {
          video.play().catch((error) => {
            console.log('Auto play failed:', error)
          })
        })
      })
    },
    handleSwipeChange(index) {
      let flag = this.currentData.swipe.find((d) => d.includes('.mp4'))
      if (flag) {
        this.$nextTick(() => {
          const video = Array.isArray(this.$refs.video)
            ? this.$refs.video[0]
            : this.$refs.video
          if (index === 0) {
            // this.videoControls = true
            this.$nextTick(() => {
              video.play().catch((error) => {
                console.log('Auto play failed:', error)
              })
            })
          } else {
            this.$nextTick(() => {
              video.pause()
            })
          }
        })
      }
    },
    imagePreview(img) {
      this.$imagePreview({
        images: [img],
        closeable: true,
      })
    },
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    },
  },
}
</script>

<style lang="less" scoped>
.homePage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #000;
  overflow: hidden;
  font-size: 4vw;
  .homePage_top {
    width: 100%;
    background: #000;
    padding: 4vw 0 6vw;
    .homePage_swipe {
      width: 100%;
      height: 35vh;
      padding-bottom: 8vw;
      ::v-deep .van-swipe__indicator {
        background-color: #00a9ff;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.3);
        width: 100vw;
        height: 100%;
      }
      .overlay_icon {
        color: #fff;
        font-size: 16vw;
        background: linear-gradient(#2595cd, #1677ff);
        padding: 0.5vw;
        border-radius: 50%;
        box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.5);
      }
      .van-swipe-item {
        width: 100%;
        height: 100%;
        background: #000;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
        video {
          width: 100%;
          height: 100%;
          background: #000;
        }
      }
    }
    .homePage_title {
      position: relative;
      padding: 0 4vw;
      margin: 0;
      color: #fff;
      text-align: center;
    }
  }
}
</style>
