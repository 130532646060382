import { render, staticRenderFns } from "./home.vue?vue&type=template&id=2e9270b6&scoped=true"
import script from "./home.vue?vue&type=script&lang=js"
export * from "./home.vue?vue&type=script&lang=js"
import style0 from "./home.vue?vue&type=style&index=0&id=2e9270b6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_vue-template-compiler@2.7.16_webpack@5.91.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e9270b6",
  null
  
)

export default component.exports